<template>
  <v-app class="add--vehicle">
    <v-dialog v-model="dialogExpired" persistent width="500px">
      <v-card>
        <v-container class="text-center">
          <v-img
            src="@/assets/img/expired-policy.png"
            class="mx-auto my-5"
            width="170"
          />
          <v-card-title>
            <h3 class="mx-auto primary--text">Sorry !</h3>
          </v-card-title>
          <v-card-text>
            <p>
              {{ expiredPolicy.response }}
            </p>
          </v-card-text>
          <v-card-actions class="vehicle--search">
            <v-btn
              class="secondary mx-auto my-5 px-12"
              large
              @click="$emit('updateExpiredDialogStatus', false)"
            >
              okay
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import validation from '@/utils/Validations'
export default {
  props: {
    dialogExpired: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    regNo: '',
    dialogInvite: false,
    expiredPolicy: {
      src: '@/assets/img/expired-policy.png',
      response:
        'Vehicle  doesn’t exist in our records, please contact the office for more details.',
    },
    ...validation,
  }),
}
</script>
<style scoped>
.add--vehicle {
  height: 0;
  float: right;
}
</style>
